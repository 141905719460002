import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "../../assets/scss/Footer/Footer.scss";
import { FooterContentType } from "../../interfaces/FooterType";
import { PageType } from "../../interfaces/Pages";
import logo from "../../resources/logo/fresenius-kabi.png";
import { authState, contentState } from "../../store/Reducer";
import { generateNav, useQuery } from "../../util/util";
import DynamicFooter from "./DynamicFooter";
import { ReactComponent as ExpandIcon } from "../../assets/icons/expand.svg";

export default function Footer(props) {
  const location = useLocation().pathname.replace("/", "");
  let mobile = useQuery().get("mobile") === "true";
  const content = useSelector(contentState);
  const auth = useSelector(authState);

  const footerContent: FooterContentType =
    content[location]?.footer ||
    content?.login?.footer ||
    content[PageType.homeOld]?.footer;
  

  const [isStaticFooterVisible, setIsStaticFooterVisible] = useState(true);
  const staticFooterRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          setIsStaticFooterVisible(entry.isIntersecting);
        });
      },
      {
        threshold: 0.1,
        rootMargin: '0px'
      }
    );
  
    if (staticFooterRef.current) {
      observer.observe(staticFooterRef.current);
    }
  
    return () => {
      observer.disconnect();
    };
  }, [content, location]);
  
  const [isSmpcOpen, setIsSmpcOpen] = useState(false);
  
  const handleSmpcToggle = () => {
    setIsSmpcOpen(!isSmpcOpen); // Toggle the state
  };
  
  //console.log("Content location: " + JSON.stringify(content[location]));
  
  const getSmpcContent = () => {
    // Check if content exists
    if (!content[location]?.footer) {
      return null;
    }
    
    const footer = content[location].footer;
    
    // Check login requirement
    if (footer.footer_smpc_only_logged_in === "1" && !auth.authenticated) {
      return null;
    }
  
    // Return default content for non-authenticated users
    if (!auth.authenticated) {
      return footer.footer_smpc_default || null;
    }
    
    // Handle roles more safely
    const roles = Array.isArray(auth.roles) ? auth.roles : 
                 typeof auth.roles === 'string' ? auth.roles.split(' ') : 
                 [];
  
    // Check role-specific content
    if (roles.includes("hcp") && footer.footer_smpc_hcp) {
      return footer.footer_smpc_hcp;
    }
    if (roles.includes("idacio") && footer.footer_smpc_idacio) {
      return footer.footer_smpc_idacio;
    }
    if (roles.includes("tyenne") && footer.footer_smpc_tyenne) {
      return footer.footer_smpc_tyenne;
    }
    if (roles.includes("otulfi") && footer.footer_smpc_otulfi) {
      return footer.footer_smpc_otulfi;
    }
  
    return footer.footer_smpc_default || null;
  };

  
  let legalNumber = "";
  if (content[location] && content[location].legal_number !== undefined) {
    legalNumber = content[location].legal_number;
  } else if(content[location] && content[location].footer_dynamic && content[location].footer_dynamic.legal !== undefined){
    legalNumber = content[location].footer_dynamic.legal; 
  }  else if (footerContent && footerContent.footer_legal !== undefined){
    legalNumber = footerContent.footer_legal;
  } else if (
      props.legal_number &&
      props.legal_number !== "XX/XXX/XXXX" &&
      props.legal_number !== "XX/XX/XXX" &&
      content[location] && content[location].legal_number !== undefined &&
      (content[location].legal_number === "XX/XXX/XXXX" ||
        content[location].legal_number === "XX/XX/XXX")
    ) {
      legalNumber = props.legal_number;
    }
  

  let peparationDate = "";
  if (content[location] && content[location].footer_preparation_date) {
    peparationDate = content[location].footer_preparation_date;
  } else if (content[location] && content[location].footer_dynamic && content[location].footer_dynamic.date){
    peparationDate = content[location].footer_dynamic.date; 
  } else if (footerContent && footerContent.footer_date_of_preparation_label){
    peparationDate = footerContent.footer_date_of_preparation_label;
  }
  
  
  if (content[location] && content[location].footer_dynamic) {

    let showSituational = false
    if(content[location] && content[location].footer_show_situational && content[location] && content[location].footer_show_situational === true){
      showSituational = true
    }
    
    let showAdverse = false
    if(content[location] && content[location].footer_show_adverse && content[location] && content[location].footer_show_adverse === true){
      showAdverse = true
    }    
    
    const envSettings = {
      alwaysShowSituational: process.env.REACT_APP_SETTING_ALWAYS_SHOW_FOOTER_SITUATIONAL === "true",
      showIdacioSituational: process.env.REACT_APP_SETTING_SHOW_FOOTER_SITUATIONAL_ROLE_IDACIO === "true",
      showTyenneSituational: process.env.REACT_APP_SETTING_SHOW_FOOTER_SITUATIONAL_ROLE_TYENNE === "true",
      showOtulfiSituational: process.env.REACT_APP_SETTING_SHOW_FOOTER_SITUATIONAL_ROLE_OTULFI === "true",      
      showHcpSituational: process.env.REACT_APP_SETTING_SHOW_FOOTER_SITUATIONAL_ROLE_HCP === "true",
      showNonAuthSituational: process.env.REACT_APP_SETTING_SHOW_FOOTER_SITUATIONAL_NON_AUTH === "true",
      alwaysShowAdverse: process.env.REACT_APP_SETTING_ALWAYS_SHOW_FOOTER_ADVERSE === "true",
      showIdacioAdverse: process.env.REACT_APP_SETTING_SHOW_FOOTER_ADVERSE_ROLE_IDACIO === "true",
      showTyenneAdverse: process.env.REACT_APP_SETTING_SHOW_FOOTER_ADVERSE_ROLE_TYENNE === "true",
      showOtulfiAdverse: process.env.REACT_APP_SETTING_SHOW_FOOTER_ADVERSE_ROLE_OTULFI === "true",
      showHcpAdverse: process.env.REACT_APP_SETTING_SHOW_FOOTER_ADVERSE_ROLE_HCP === "true",
      showNonAuthAdverse: process.env.REACT_APP_SETTING_SHOW_FOOTER_ADVERSE_NON_AUTH === "true",      
    };
    
    if (mobile) return <></>;    
    
    return (
      <DynamicFooter
        {...content[location].footer_dynamic}
        legal_number={legalNumber}
        preparation_date={peparationDate}
        show_situational={showSituational}
        showAdverse={showAdverse}
        isAuthenticated={auth.authenticated}
        userRoles={auth.user.roles.split(' ')}
        envSettings={envSettings}        
      />
    );
  }
  
  
  if (!footerContent) {
    return <></>;
  }
  
  
  const showSituationalDisclaimer = 
    (footerContent.footer_situational_always && footerContent.footer_situational_always === "1") ||
    process.env.REACT_APP_SETTING_ALWAYS_SHOW_FOOTER_SITUATIONAL === "true" ||
    (process.env.REACT_APP_SETTING_SHOW_FOOTER_SITUATIONAL_ROLE_IDACIO === "true" && auth.roles?.includes("idacio")) ||
    (process.env.REACT_APP_SETTING_SHOW_FOOTER_SITUATIONAL_ROLE_TYENNE === "true" && auth.roles?.includes("tyenne")) ||
    (process.env.REACT_APP_SETTING_SHOW_FOOTER_SITUATIONAL_ROLE_OTULFI === "true" && auth.roles?.includes("otulfi")) ||
    (process.env.REACT_APP_SETTING_SHOW_FOOTER_SITUATIONAL_ROLE_HCP === "true" && auth.roles?.includes("hcp")) ||
    (process.env.REACT_APP_SETTING_SHOW_FOOTER_SITUATIONAL_NON_AUTH === "true" && !auth.authenticated);

  const showAdverseDisclaimer = 
    (footerContent.footer_adverse_always && footerContent.footer_adverse_always === "1") ||
    process.env.REACT_APP_SETTING_ALWAYS_SHOW_FOOTER_ADVERSE === "true" ||
    (process.env.REACT_APP_SETTING_SHOW_FOOTER_ADVERSE_ROLE_IDACIO === "true" && auth.roles?.includes("idacio")) ||
    (process.env.REACT_APP_SETTING_SHOW_FOOTER_ADVERSE_ROLE_TYENNE === "true" && auth.roles?.includes("tyenne")) ||
    (process.env.REACT_APP_SETTING_SHOW_FOOTER_ADVERSE_ROLE_OTULFI === "true" && auth.roles?.includes("otulfi")) ||    
    (process.env.REACT_APP_SETTING_SHOW_FOOTER_ADVERSE_ROLE_HCP === "true" && auth.roles?.includes("hcp")) ||
    (process.env.REACT_APP_SETTING_SHOW_FOOTER_ADVERSE_NON_AUTH === "true" && !auth.authenticated);    
    
  console.log("SMPC Content to render:", getSmpcContent());
  return (
    <div>
      
      {getSmpcContent() && (
        <div className={`footer-smpc-fixed ${isStaticFooterVisible ? 'hide-smpc' : ''} ${isSmpcOpen ? 'smpc-expanded' : ''}`}>
          <div className="open-smpc" onClick={handleSmpcToggle}><ExpandIcon /></div>
            <div className="smpc-inner">
              {/* Use the content directly as HTML */}
              <div dangerouslySetInnerHTML={{
                __html: getSmpcContent() || ''
              }} />
            </div>
        </div>  
      )}
      
      <div className={"footer"}>
        <div className="footer-smpc-static" ref={staticFooterRef} style={{ display: getSmpcContent() ? 'block' : 'none' }}>
          <div className="smpc-inner">
            {/* Use the content directly as HTML */}
            <div dangerouslySetInnerHTML={{
              __html: getSmpcContent() || ''
            }} />
          </div> 
        </div>  
        <div className="footer-inner-container">
          {showSituationalDisclaimer  && (
            <div
              className={"disclaimer situational"}
              dangerouslySetInnerHTML={{
                __html: footerContent?.footer_disclaimer_situational,
              }}
            ></div>
          )}
          {showAdverseDisclaimer && (
            <div
              className={"disclaimer situational"}
              dangerouslySetInnerHTML={{
                __html: footerContent?.footer_disclaimer_adverse,
              }}
            />
          )}            
          <div
            className={"disclaimer"}
            dangerouslySetInnerHTML={{
              __html: footerContent?.footer_disclaimer_normal,
            }}
          ></div>
          <div className={"bottom"}>
            <div className={"logo"}>
              <img src={logo} alt="Logo" width="238" height="64" />
            </div>
            <div className={"content"}>
              <div className="preparation-container">
                <div className={"date"}>
                  <p>{footerContent?.footer_date_of_preparation_label}</p>
                </div>
                <div className="legal-number">{legalNumber}</div>
              </div>
              <div className={"links"}>
                {generateNav(footerContent?.footer_menu)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
import Button from "@material-ui/core/Button";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import "../../assets/scss/Popup/Popup.scss";

export interface DialogProps {
  title?: string;
  button1Text?: string;
  button2Text?: string;
  contentText?: string;
  renderContent?: JSX.Element;
  className?: string;
  onButton1?: () => void;
  onButton2?: () => void;
  open: boolean;
}

export default function Popup({
  title,
  button1Text,
  button2Text,
  contentText,
  renderContent,
  className,
  onButton1,
  onButton2,
  open,
}: DialogProps) {
  return (
    <MuiDialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={className}
    >
      <DialogTitle id="alert-dialog-title" disableTypography={true}>
        <h2>{title}</h2>
      </DialogTitle>
      <DialogContent>
        {contentText && (
          <div className="video-container">
            <iframe 
              src={contentText}
              width="100%"
              height="400"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </div>
        )}
        {renderContent}
      </DialogContent>
      <DialogActions
        style={{textAlign: "center"}}
      >
        {button1Text && (
          <Button
            onClick={onButton1}
            color="primary"
            className="button2"
            aria-label={button1Text}
          >
            {button1Text}
          </Button>
        )}
        {button2Text && (
          <Button onClick={onButton2} color="primary" aria-label={button2Text}>
            {button2Text}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
}

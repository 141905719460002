import "../../assets/scss/Footer/Footer.scss";
import { LinkType } from "../../interfaces/LinkType";
import logo from "../../resources/logo/fresenius-kabi.png";
import { generateFooterLinks } from "../../util/util";
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { ReactComponent as ExpandIcon } from "../../assets/icons/expand.svg";


export interface DynamicFooterType {
  disclaimer_normal: string;
  disclaimer_situational: string;
  disclaimer_situational_vis: boolean;
  disclaimer_adverse: string;
  disclaimer_adverse_vis: boolean;  
  date: string;
  links: Array<LinkType>;
  legal_number: string;
  preparation_date: string;
  show_situational: boolean;
  show_adverse: boolean;  
  isAuthenticated?: boolean;
  userRoles?: string[];
  envSettings?: {
    alwaysShowSituational?: boolean;
    showIdacioSituational?: boolean;
    showTyenneSituational?: boolean;
    showOtulfiSituational?: boolean;    
    showHcpSituational?: boolean;
    showNonAuthSituational?: boolean;
    alwaysShowAdverse?: boolean;
    showIdacioAdverse?: boolean;
    showTyenneAdverse?: boolean;
    showOtulfiAdverse?: boolean;    
    showHcpAdverse?: boolean;
    showNonAuthAdverse?: boolean;    
  };
  smpc_default?: string;
  smpc_hcp?: string;
  smpc_idacio?: string;
  smpc_otulfi?: string;
  smpc_tyenne?: string;
  smpc_only_logged_in?: boolean;  
}

export default function DynamicFooter({
  disclaimer_normal = "",
  disclaimer_situational = "",
  disclaimer_situational_vis = false,
  disclaimer_adverse = "",
  disclaimer_adverse_vis = false,  
  date = "",
  links = [],
  legal_number = "",
  preparation_date = "",
  show_situational = false,
  show_adverse = false,  
  isAuthenticated = false,
  userRoles = [],
  envSettings = {
    alwaysShowSituational: false,
    showIdacioSituational: false,
    showTyenneSituational: false,
    showOtulfiSituational: false,    
    showHcpSituational: false,
    showNonAuthSituational: false,
    alwaysShowAdverse: false,
    showIdacioAdverse: false,
    showTyenneAdverse: false,
    showOtulfiAdverse: false,    
    showHcpAdverse: false,
    showNonAuthAdverse: false,
  },
  smpc_default = "",
  smpc_hcp = "",
  smpc_idacio = "",
  smpc_otulfi = "",
  smpc_tyenne = "",
  smpc_only_logged_in = false,  
}: DynamicFooterType) {
  
  
  //console.log("Footer Dynamic Props:", arguments[0]);
  
  const [isStaticFooterVisible, setIsStaticFooterVisible] = useState(true);
  const staticFooterRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    // Create intersection observer to watch the static footer
    const observer = new IntersectionObserver(
      (entries) => {
        // Update state based on intersection
        entries.forEach(entry => {
          setIsStaticFooterVisible(entry.isIntersecting);
        });
      },
      {
        // Options for the observer
        threshold: 0.1, // Trigger when at least 10% of the element is visible
        rootMargin: '0px' // No margin
      }
    );

    // Start observing the static footer if ref is available
    if (staticFooterRef.current) {
      observer.observe(staticFooterRef.current);
    }

    // Cleanup observer when component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);
  
  const [isSmpcOpen, setIsSmpcOpen] = useState(false);
  
  const handleSmpcToggle = () => {
    setIsSmpcOpen(!isSmpcOpen); // Toggle the state
  };
  
  
  const getSmpcContent = () => {
    if (smpc_only_logged_in && !isAuthenticated) {
      return null;
    }

    if (!isAuthenticated) {
      return smpc_default || null;
    }

    if (userRoles?.includes("hcp") && smpc_hcp) {
      return smpc_hcp;
    }
    if (userRoles?.includes("idacio") && smpc_idacio) {
      return smpc_idacio;
    }
    if (userRoles?.includes("tyenne") && smpc_tyenne) {
      return smpc_tyenne;
    }
    if (userRoles?.includes("otulfi") && smpc_otulfi) {
      return smpc_otulfi;
    }

    return null;
  };  
  
  
  const showSituationalDisclaimer = 
    show_situational === true ||
    disclaimer_situational_vis === true ||
    envSettings?.alwaysShowSituational ||
    (envSettings?.showIdacioSituational && userRoles?.includes("idacio")) ||
    (envSettings?.showTyenneSituational && userRoles?.includes("tyenne")) ||
    (envSettings?.showOtulfiSituational && userRoles?.includes("otulfi")) ||    
    (envSettings?.showHcpSituational && userRoles?.includes("hcp")) ||
    (envSettings?.showNonAuthSituational && !isAuthenticated);  

  const showAdverseDisclaimer = 
    show_adverse === true ||
    disclaimer_adverse_vis === true ||
    envSettings?.alwaysShowAdverse ||
    (envSettings?.showIdacioAdverse && userRoles?.includes("idacio")) ||
    (envSettings?.showTyenneAdverse && userRoles?.includes("tyenne")) ||
    (envSettings?.showOtulfiAdverse && userRoles?.includes("otulfi")) ||    
    (envSettings?.showHcpAdverse && userRoles?.includes("hcp")) ||
    (envSettings?.showNonAuthAdverse && !isAuthenticated);    
  
  return (
    <div>
      {getSmpcContent() && (
        <div className={`footer-smpc-fixed ${isStaticFooterVisible ? 'hide-smpc' : ''} ${isSmpcOpen ? 'smpc-expanded' : ''}`}>
          <div className="open-smpc" onClick={handleSmpcToggle}><ExpandIcon /></div>
            <div className="smpc-inner">
              {/* Use the content directly as HTML */}
              <div dangerouslySetInnerHTML={{
                __html: getSmpcContent() || ''
              }} />
            </div>
        </div>  
      )}
    
      <div className={"footer"}>
          <div className="footer-smpc-static" ref={staticFooterRef} style={{ display: getSmpcContent() ? 'block' : 'none' }}>
            <div className="smpc-inner">
              {/* Use the content directly as HTML */}
              <div dangerouslySetInnerHTML={{
                __html: getSmpcContent() || ''
              }} />
            </div> 
          </div>      
        <div className="footer-inner-container">
          {showSituationalDisclaimer && (
            <div
              className={"disclaimer situational"}
              dangerouslySetInnerHTML={{
                __html: disclaimer_situational,
              }}
            />
          )}
          {showAdverseDisclaimer && (
            <div
              className={"disclaimer situational"}
              dangerouslySetInnerHTML={{
                __html: disclaimer_adverse,
              }}
            />
          )}        
          <div
            className={"disclaimer"}
            dangerouslySetInnerHTML={{
              __html: disclaimer_normal,
            }}
          ></div>
          <div className={"bottom"}>
            <div className={"logo"}>
              <img src={logo} alt="Logo" width="238" height="64" />
            </div>
            <div className={"content"}>
              <div className="preparation-container">
                <div className={"date"}>
                  <p>{preparation_date}</p>
                </div>
                <div className="legal-number">{legal_number}</div>
              </div>
              <div className={"links"}>{generateFooterLinks(links)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  );
}

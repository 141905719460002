import { useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "./PopupAccessCode";

export const VideoPopup = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <a  // Added the opening <a> tag here
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
        className="video-helper-text"
      >
        {t("register.form.patient.videoHelperText")}
      </a>

      <Popup
        open={open}
        onButton1={() => {
          setOpen(false);
        }}
        className={"video-popup"}
        title={t("register.form.patient.videoPopup.title")}
        contentText={t("register.form.patient.videoPopup.src")}
        button1Text={t("register.form.patient.videoPopup.close")}
      />
    </>
  );
};